<template>
  <div>
    <!--面包屑-->
    <div class="page-breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/groupbuy/list' }">拼团活动</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/groupbuy/list/edit' }">{{
            isEdit ? "编辑拼团产品" : "添加拼团产品"
        }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!--内容-->
    <div class="page-content-x">
      <el-row>
        <el-col :span="16">
          <h3>{{ isEdit ? "编辑拼团产品" : "添加拼团产品" }}</h3>
        </el-col>
      </el-row>
      <el-form ref="form" :model="form" size="medium" :label-width="this.env.label_width" v-loading="loading">
        <el-row>
          <el-col :span="16">
            <!-- 设置拼团名称 -->
            <el-form-item label="拼团名称">
              <el-input style="width: 100%" placeholder="请输入拼团活动名称" v-model="form.groupbuy_name" />
            </el-form-item>
            <!-- 添加产品按钮 -->
            <el-form-item :label="!isEdit ? '添加产品' : '产品名'">
              <el-button v-if="this.Tool.is_auth('marketing.groupbuy.usableproduct') && !isEdit" size="medium"
                type="primary" @click="getJoinProduct">添加产品
              </el-button>
              <div v-if="chooseItemData.length > 0 && !isEdit">
                <p>
                  {{
                      groupBuyType === "group"
                        ? "团课"
                        : groupBuyType === "camp"
                          ? "训练营"
                          : groupBuyType === "activity"
                            ? "活动"
                            : groupBuyType === "commodity"
                              ? "商品"
                              : ""
                  }}：
                </p>
                <p v-for="(item, index) in chooseItemData" :key="index" style="color: #999; line-height: 24px">
                  <span v-if="groupBuyType === 'group' || groupBuyType === 'camp'">
                    {{
                        `${index + 1} - ${item.title} - 价格：¥${item.price} - 上课时间：${item.start_time
                        }`
                    }}
                  </span>
                  <span v-else-if="groupBuyType === 'activity'">
                    {{
                        `${index + 1} - ${item.title} - 价格：¥${item.price} - 报名时间：${item.signup_start_time
                        } - 活动时间：${item.start_time}`
                    }}
                  </span>
                  <span v-else>
                    {{ `${index + 1} - ${item.title} - 价格：¥${item.price}` }}
                  </span>
                </p>
              </div>
              <div v-else>
                <span>{{ form.product_title }}</span>
              </div>
            </el-form-item>
            <!-- 规格设置 -->
            <el-form-item label="人数/价格设置">
              <div v-for="(item, index) in format" :key="index">
                <el-input-number size="small" :step-strictly="true" v-model="format[index]" :min="1">
                </el-input-number>
                <el-input-number :min="0.01" :max="minPrice" :step="0.01" placeholder="拼团价格" size="small"
                  style="width: 120px; margin-left: 24px" v-model="formatPrice[index]" />
                <el-button v-if="format.length !== 1 && !isEdit" style="margin-left: 20px" type="text" class="del_btn"
                  @click="del_qty(index)">删除</el-button>
              </div>
              <el-button v-if="format.length < 3" type="text" @click="add_format">添加</el-button>
              <p style="color: #fc142f">最高可设置拼团价格：¥{{ minPrice }}</p>
            </el-form-item>

            <el-form-item label="发起规则">
              <el-input type="textarea" :rows="3" :maxlength="200" show-word-limit v-model="form.initiate_rule">
              </el-input>
            </el-form-item>
            <el-form-item label="参与规则">
              <el-input type="textarea" :rows="3" :maxlength="200" show-word-limit v-model="form.participate_rule">
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-button v-if="this.Tool.is_auth('marketing.groupbuy.issave')" size="medium" type="primary"
                @click="save">保存
              </el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <el-dialog v-loading="loading" title="添加产品列表" :visible.sync="order_dialog" width="70%"
      :close-on-press-escape="false" :close-on-click-modal="false" @close="checkProduct">
      <el-radio-group v-model="groupBuyType" style="margin-bottom: 24px" @change="changeGroupBuyType">
        <el-radio label="group">团课</el-radio>
        <el-radio label="camp">训练营</el-radio>
        <el-radio label="activity">活动</el-radio>
        <el-radio label="commodity">商品</el-radio>
      </el-radio-group>
      <el-table size="medium" border v-loading="loading" :data="groupBuyDataList" style="width: 100%">
        <el-table-column prop="title" label="产品名称" />
        <el-table-column v-if="groupBuyType === 'group' || groupBuyType === 'camp'" prop="user_nick" label="教练名称" />
        <el-table-column v-if="groupBuyType === 'group' || groupBuyType === 'camp'" prop="store_name" label="门店名称" />
        <el-table-column prop="price" label="购买价格" />
        <el-table-column prop="originally_price" label="原价价格">
          <template slot-scope="scope">
            <span>{{ `${scope.row.originally_price}` }}</span>
          </template>
        </el-table-column>
        <el-table-column v-if="groupBuyType === 'activity' || groupBuyType === 'commodity'" prop="promotion_price"
          label="促销价格" />
        <el-table-column v-if="groupBuyType === 'group' || groupBuyType === 'camp'" prop="bookings_sum" label="已购人数">
          <template slot-scope="scope">
            <span>{{ `${scope.row.bookings_sum}` }}</span>
          </template>
        </el-table-column>
        <el-table-column v-if="groupBuyType === 'group' || groupBuyType === 'camp'" prop="quota" label="人数限制" />
        <el-table-column v-if="groupBuyType === 'activity'" prop="start_time" label="报名时间">
          <template slot-scope="scope">
            <span>{{
                `${scope.row.signup_start_time} - ${scope.row.signup_end_time}`
            }}</span>
          </template>
        </el-table-column>
        <el-table-column v-if="groupBuyType === 'activity'" prop="start_time" label="活动时间">
          <template slot-scope="scope">
            <span>{{ `${scope.row.start_time} - ${scope.row.end_time}` }}</span>
          </template>
        </el-table-column>
        <el-table-column v-if="groupBuyType === 'group' || groupBuyType === 'camp'" prop="start_time" label="开始时间" />
        <el-table-column v-if="groupBuyType === 'group' || groupBuyType === 'camp'" prop="end_time" label="结束时间" />
        <el-table-column prop="create_time" label="发布时间" />
        <el-table-column prop="publish_state" label="上下架状态" width="100">
          <template slot-scope="scope">
            <el-tag size="mini" v-if="scope.row.publish_state === 0" type="warning">下架</el-tag>
            <el-tag size="mini" v-if="scope.row.publish_state === 1" type="success">上架</el-tag>
            <el-tag size="mini" v-if="scope.row.publish_state === 2" type="warning">审核中</el-tag>
            <el-tag size="mini" v-if="scope.row.publish_state === 3" type="danger">不通过</el-tag>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作">
          <template slot-scope="scope">
            <div v-if="!scope.row.isDisabled">
              <el-button v-if="!scope.row.isChoose" size="mini" type="primary"
                @click="selectProduct(scope.row.product_uuid, scope.row)">选择
              </el-button>
              <el-button v-else @click="deletUser(scope.row.product_uuid, scope.row)" type="danger" size="mini">已选择
              </el-button>
            </div>
            <el-button disabled v-else type="primary" size="mini">选择</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="height: 20px"></div>
      <!--分页-->
      <el-pagination @current-change="getNextGroupBuyList" :page-size="this.env.pageSize" :pager-count="7" background
        layout="prev, pager, next, total" :current-page.sync="page" :total="count">
      </el-pagination>
      <el-row style="margin: 10px 0px">
        <el-button size="medium" type="primary" @click="checkProduct">确 定 </el-button>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import env from "@/../config/env";
import { Message, MessageBox } from "element-ui";
export default {
  data() {
    return {
      format: [2, 3, 4], //规格设置
      form: {},
      loading: true,
      formatPrice: [0, 0, 0],
      order_dialog: false,
      joinProduct: {},
      page: 1,
      groupBuyDataList: [],
      chooseItemIdData: [], // 以选择可拼团产品id列表数据
      chooseItemData: [], // 以选择可拼团产品名字列表数据
      count: 0,
      groupBuyType: "group",
      isEdit: false,
      minPrice: 0,
    };
  },
  created() {
    this.getGroupBuyList();
    this.init();
  },
  methods: {
    init() {
      let groupbuy_uuid = this.$route.query.groupbuy_uuid;
      if (groupbuy_uuid !== undefined) {
        this.groupbuy_uuid = groupbuy_uuid;
        this.isEdit = true;
        this.getinfo();
      } else {
        this.loading = false;
      }
    },
    // 拼团详情
    getinfo() {
      let postdata = {
        api_name: "marketing.groupbuy.getinfo",
        token: this.Tool.get_l_cache("token"),
        groupbuy_uuid: this.groupbuy_uuid,
      };
      this.loading = true;
      this.Tool.post_data("merchant", postdata, (json) => {
        this.loading = false;
        if (json.code === 0) {
          this.form = json.data;
          const sku = json.data.sku;
          let pricrData = [],
            formatData = [];
          for (let i in sku) {
            pricrData = pricrData.concat(sku[i].price);
            formatData = formatData.concat(sku[i].quota);
          }
          this.groupBuyType = json.data.product;
          this.format = formatData;
          this.formatPrice = pricrData;
          this.minPrice = json.data.price_ceiling;
        } else {
          this.Tool.errormes(json);
        }
      });
    },
    // 权限判断
    is_auth(auth) {
      return this.Tool.is_auth(auth);
    },
    // 添加规格
    add_format() {
      const { format } = this;
      if (format.indexOf(2) === -1) {
        this.format.push(2);
      } else if (format.indexOf(3) === -1) {
        this.format.push(3);
      } else if (format.indexOf(4) === -1) {
        this.format.push(4);
      }
      this.formatPrice.push(0);
    },
    // 删除规格
    del_qty(index) {
      this.format.splice(index, 1);
      this.formatPrice.splice(index, 1);
    },
    // 提交表单
    save() {
      const {
        format,
        form,
        formatPrice,
        chooseItemIdData,
        chooseItemData,
        groupBuyType,
        isEdit,
        minPrice,
      } = this;
      const arr = [];
      const productArr = [];
      for (let i = 0; i < format.length; i++) {
        for (let j = 0; j < format.length; j++) {
          if (format[j + i + 1]) {
            if (format[i] === format[i + j + 1]) {
              this.$message.error("不可同时出现多条相同人数的规则");
              return false;
            }
          }
        }
      }
      format.map((item, index) => arr.push({ quota: item, price: formatPrice[index] }));
      chooseItemIdData.map((item, index) =>
        productArr.push({
          product_uuid: item,
          product_title: chooseItemData[index].title,
          timedata: chooseItemData[index].lesson_time,
        })
      );
      const postData = {
        ...form,
        api_name: "marketing.groupbuy.issave",
        token: this.Tool.get_l_cache("token"),
        sku: arr,
        product_uuid_all: productArr,
        product: groupBuyType,
        price_ceiling: minPrice,
      };
      this.Tool.post_data("merchant", postData, (json) => {
        if (json.message_all) {
          let message = '<p style="line-height: 20px;">';
          for (let val_tip of json.message_all) {
            message += `${val_tip}</br>`;
          }
          message += "</p>";
          MessageBox.alert(message, "信息提醒", {
            confirmButtonText: "关闭",
            dangerouslyUseHTMLString: true,
            type: "error",
            callback: () => {
              this.$router.push({ path: "/groupbuy/list" });
            },
          });
        } else {
          if (json.code === 0) {
            this.$message.success(isEdit ? "修改成功" : "添加成功！");
            const timer = setTimeout(() => {
              clearTimeout(timer);
              this.$router.push({ path: "/groupbuy/list" });
            }, 1000);
          } else if (json.code === 10001) {
            Message({
              message: json.tip_message ? json.tip_message : json.message,
              duration: env.message_error,
            });
          } else {
            this.Tool.errormes(json);
          }
        }
      });
    },
    // 查看已添加产品
    getJoinProduct() {
      this.order_dialog = true;
    },
    // 切换产品线
    changeGroupBuyType() {
      this.chooseItemData = [];
      this.chooseItemIdData = [];
      this.groupBuyDataList = [];
      this.getGroupBuyList();
    },
    getNextGroupBuyList() {
      this.getGroupBuyList();
    },
    // 获取可用产品列表
    getGroupBuyList(lessId) {
      const { chooseItemIdData, groupBuyType } = this;
      let postdata = {
        api_name: "marketing.groupbuy.usableproduct",
        token: this.Tool.get_l_cache("token"),
        product: this.groupBuyType,
        pagesize: this.env.pageSize,
        page: this.page,
      };
      if (lessId) {
        postdata["lesson_uuid"] = lessId;
      }
      // 非必须参数（搜索条件）
      Object.assign(postdata, this.search);
      this.loading = true;
      this.Tool.post_data("merchant", postdata, (json) => {
        this.loading = false;
        if (json.code === 0) {
          const tableData = json.data.list;
          for (let i in tableData) {
            if (chooseItemIdData.length > 0) {
              for (let j in chooseItemIdData) {
                if (tableData[i].product_uuid === chooseItemIdData[j]) {
                  tableData[i].isChoose = true;
                  if (groupBuyType === "activity" || groupBuyType === "commodity") {
                    tableData[i].isDisabled = false;
                  }
                  break;
                } else {
                  tableData[i].isChoose = false;
                  if (groupBuyType === "activity" || groupBuyType === "commodity") {
                    tableData[i].isDisabled = true;
                  }
                }
              }
            } else {
              tableData[i].isChoose = false;
            }
          }
          this.groupBuyDataList = tableData;
          this.count = json.data.count;
        } else {
          this.groupBuyDataList = [];
          this.Tool.errormes(json);
          this.count = 0;
        }
      });
    },
    //选择可参加拼团产品
    selectProduct(id, item) {
      const { chooseItemData, chooseItemIdData, groupBuyDataList, groupBuyType } = this;
      if (
        chooseItemIdData.length === 0 &&
        (groupBuyType === "group" || groupBuyType === "camp")
      ) {
        /**
         * 如果当前选择的产品是团课或者训练营，
         * 并且当前没有选择产品为第一个选择的话，
         * 请求接口返回相同课程不同时间段的课程列表
         */
        this.page = 1;
        this.getGroupBuyList(item.lesson_uuid);
      }
      chooseItemIdData.push(id);
      chooseItemData.push(item);
      this.chooseItemIdData = chooseItemIdData;
      this.chooseItemData = chooseItemData;
      // 循环数组修改产品列表的选择状态
      for (let i in groupBuyDataList) {
        if (groupBuyDataList[i].product_uuid === id) {
          groupBuyDataList[i].isChoose = true;
        } else {
          if (groupBuyType === "activity" || groupBuyType === "commodity")
            groupBuyDataList[i].isDisabled = true;
        }
      }
      this.groupBuyDataList = groupBuyDataList;
    },
    // 删除可参加拼团产品
    deletUser(id) {
      const { chooseItemData, chooseItemIdData, groupBuyDataList, groupBuyType } = this;
      const index = chooseItemIdData.indexOf(id);
      chooseItemIdData.splice(index, 1);
      chooseItemData.splice(index, 1);
      // 循环数组修改产品列表的选择状态
      for (let i in groupBuyDataList) {
        if (groupBuyDataList[i].product_uuid === id) {
          groupBuyDataList[i].isChoose = false;
        } else {
          if (groupBuyType === "activity" || groupBuyType === "commodity") {
            groupBuyDataList[i].isDisabled = false;
          }
        }
      }
      this.chooseItemIdData = chooseItemIdData;
      this.chooseItemData = chooseItemData;
      this.groupBuyDataList = groupBuyDataList;
      if (
        chooseItemIdData.length === 0 &&
        (groupBuyType === "group" || groupBuyType === "camp")
      ) {
        this.getGroupBuyList();
        this.page = 1;
      }
    },
    checkProduct() {
      const { chooseItemData } = this;
      let itemPriceArr = [];
      chooseItemData.map((item) => itemPriceArr.push(item.price));
      if (itemPriceArr.length > 0) {
        this.minPrice = Math.min(...itemPriceArr);
      } else {
        this.minPrice = 0;
      }
      this.order_dialog = false;
    },
  },
};
</script>
